import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Elements
import Showcase from 'components/elements/Showcase'
import ButtonDefault from 'components/elements/ButtonDefault'

// Third Party
import styled from 'styled-components'

const StyledVacatures = styled.div``

const Vacatures = ({ className }) => {
  const {
    vacatures: {
      nodes: vacatures
    }
  } = useStaticQuery(graphql`{
    vacatures: allWordpressWpVacancies {
      nodes {
        path
        acf {
          preview {
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
        title
      }
    }
  }`)
  
  const [chunk, setChunk] = useState(4)

  return (
    <StyledVacatures className={`container ${className ? `${className}` : ``}`}>
      <div className="row">
        {vacatures.slice(0, chunk).map(v => (
          <div className="col-lg-6 pb-lg-5 pb-3 d-flex justify-content-center">
            <Showcase showcase={v} />
          </div>
        ))}
      </div>
      {vacatures.length > chunk && (
        <div className="d-flex justify-content-center py-lg-5">
          <ButtonDefault onClick={() => setChunk(chunk + 2)}>
            Bekijk meer vacatures
          </ButtonDefault>
        </div>
      )}
    </StyledVacatures>
  )
}

export default Vacatures